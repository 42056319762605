import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WpBlog from "../components/wpBlog"

const content =
  "TAG Finance and Loans Pty Ltd ACN 609 906 863 Credit Representative Number 483873 have access to a panel of lenders via National Mortgage Brokers Pty Ltd ACN 093 874 376 / Australian Credit Licence 391209, which is a fully-owned subsidiary of Liberty Financial Pty Ltd ACN 077 248 983 / Australian Credit Licence 286596. Our brokers have access to products including those from Liberty Financial."
const BlogPage = () => {
  const storyblokData = useStaticQuery(graphql`
    {
      allStoryblokEntry(
        sort: { fields: [first_published_at], order: DESC }
        filter: { field_component: { eq: "post" } }
      ) {
        nodes {
          name
          full_slug
          id
          content
          uuid
          first_published_at
        }
      }
    }
  `)

  return (
    <Layout subfooter={content}>
      <SEO title="Insights" />
      <WpBlog blogPreviews={storyblokData.allStoryblokEntry.nodes} />
    </Layout>
  )
}

export default BlogPage
